import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { LabeledValue } from "antd/es/select";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { FormItem, SelectField } from "@mapmycustomers/ui";

import PeopleAssociation from "@app/component/associations/PeopleAssociation";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import ChangeAssociationsPayload from "@app/store/associations/ChangeAssociationsPayload";
import { fetchPerson } from "@app/store/person/actions";
import showRelateEntitiesAlert from "@app/util/form/showRelateEntitiesAlert";

import styles from "./CommonRelationshipField.module.scss";
import RelateEntitiesRequestPayload from "./RelateEntitiesRequestPayload";

interface Props {
  allowAdd?: boolean;
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  onChangeAssociatedEntities?: (payload: Omit<ChangeAssociationsPayload, "entityType">) => void;
  onFetchPerson: typeof fetchPerson;
  relateEntities?: (payload: RelateEntitiesRequestPayload) => void;
  required?: boolean;
  suggestedPeople?: Person[];
  zIndex?: number;
}

const PersonRelationshipField: React.FC<Props> = ({
  allowAdd,
  associatedCompany,
  associatedDeal,
  associatedPerson,
  className,
  disabled,
  label,
  onChangeAssociatedEntities,
  onFetchPerson,
  relateEntities,
  required,
  suggestedPeople,
  zIndex,
}) => {
  const form = useFormInstance();
  const intl = useIntl();

  const [modalVisible, showModal, hideModal] = useBoolean();
  const [selectedPerson, setSelectedPerson] = useState(associatedPerson);

  const handleSelect = useCallback(
    (personIds: Person["id"][]) => {
      const personId = personIds[0];
      onFetchPerson({
        id: personId,
        callback: (person: Person) => {
          setSelectedPerson(person);
          form.setFieldValue("personId", personId);
          onChangeAssociatedEntities?.({
            assignee: form.getFieldValue("assignee"),
            company: associatedCompany ?? (person.primaryAccount as Company) ?? undefined,
            deal: associatedDeal,
            person,
          });

          showRelateEntitiesAlert(intl, associatedCompany, person, associatedDeal, relateEntities);
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [
      associatedCompany,
      associatedDeal,
      form,
      intl,
      onChangeAssociatedEntities,
      onFetchPerson,
      relateEntities,
    ]
  );

  const handleRemove = useCallback(() => {
    onChangeAssociatedEntities?.({
      assignee: form.getFieldValue("assignee"),
      company: associatedCompany,
      deal: associatedDeal,
      person: undefined,
    });
    form.setFieldValue("personId", undefined);
  }, [associatedCompany, associatedDeal, form, onChangeAssociatedEntities]);

  const options = useMemo<LabeledValue[]>(
    () =>
      selectedPerson
        ? [{ key: String(selectedPerson.id), label: selectedPerson.name, value: selectedPerson.id }]
        : [],
    [selectedPerson]
  );

  return (
    <>
      <FormItem label={label} name="personId" required={required} rules={[{ required }]}>
        <SelectField<Person["id"][]>
          allowClear
          className={className}
          label={label}
          locked={disabled}
          onClear={handleRemove}
          onClick={disabled ? undefined : showModal}
          open={false}
          options={options}
          prefixIcon={
            <EntityTypeShapedIcon
              containerClassName={styles.entityIcon}
              entityType={EntityType.PERSON}
            />
          }
          required={required}
          showArrow={false}
        />
      </FormItem>

      {modalVisible && (
        <PeopleAssociation
          allowAdd={allowAdd}
          assignedPeople={associatedPerson ? [associatedPerson] : undefined}
          fixedCompany={associatedCompany}
          multiselect={false}
          onHide={hideModal}
          onSelect={handleSelect}
          suggestedPeople={suggestedPeople}
          zIndex={zIndex}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchPerson: fetchPerson,
};

export default connect(null, mapDispatchToProps)(PersonRelationshipField);
