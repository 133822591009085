import { isSameDay } from "date-fns/esm";
import { defineMessages } from "react-intl";

import { TripType } from "@mapmycustomers/shared";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { GeoPoint } from "@mapmycustomers/shared/types";
import { Company, Person } from "@mapmycustomers/shared/types/entity";
import Trip from "@mapmycustomers/shared/types/entity/Trip";
import { getFormattedAddressForUi } from "@mapmycustomers/shared/util/formatters";

import i18nService from "@app/config/I18nService";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import { createField } from "@app/util/fieldModel/impl/fieldModelUtil";
import { meterDistanceFormatter, moneyFormatter } from "@app/util/fieldModel/impl/fieldUtil";
import UserField from "@app/util/fieldModel/impl/UserField";
import { csvDateTimeFormatter, csvNumberFormatter, formatDate } from "@app/util/formatters";
import isValidDate from "@app/util/isValidDate";
import { parseApiDate } from "@app/util/parsers";
import getTripTypeDisplayName from "@app/util/ui/getTripTypeDisplayName";

const messages = defineMessages({
  differentDaysFormat: {
    id: "trip.date.formatter.differentDays",
    defaultMessage: "{startDateTime} - {endDateTime}",
  },
  sameDayFormat: {
    id: "trip.date.formatter.sameDay",
    defaultMessage: "{startDateTime} - {endTime}",
  },
});

interface LocationValue {
  address: null | string;
  entity: Company | null | Person;
  geoPoint: GeoPoint;
}

const locationValueFormatter = (value: LocationValue) => {
  if (value.entity) {
    const address = getFormattedAddressForUi(value.entity.geoAddress);
    return `${value.entity.name}${address ? `, ${address}` : ""}`;
  }
  return value.address ?? value.geoPoint?.coordinates.join(", ") ?? "";
};

export enum TripFieldName {
  START_TIME = "startTime",
  FINISH_TIME = "endTime",
  USER = "user",
  START = "startGeopoint",
  FINISH = "endGeopoint",
  TYPE = "type",
  NOTE = "note",
  DISTANCE = "distance",
  RATE = "tripRate",
  TOTAL_COST = "totalCost", // distance * rate
  PARKING_FEE = "parkingFee",
  TOLL_FEE = "tollFee",
  TRIP_COST = "tripCost", // parking + toll + totalCost
  BUSINESS_COST = "businessCost", // artificial, equal to tripCost for business trips
  RECORD = "record", // artificial, used for filtering
}

const displayNames = defineMessages<TripFieldName>({
  [TripFieldName.BUSINESS_COST]: {
    id: "trip.field.businessCost",
    defaultMessage: "Business Cost",
    description: "Business Cost field in trip field model",
  },
  [TripFieldName.DISTANCE]: {
    id: "trip.field.distance",
    defaultMessage: "Distance",
    description: "Distance field in trip field model",
  },
  [TripFieldName.FINISH]: {
    id: "trip.field.endGeoPoint",
    defaultMessage: "Ending Location",
    description: "Finish field in trip field model",
  },
  [TripFieldName.FINISH_TIME]: {
    id: "trip.field.endTime",
    defaultMessage: "Finish Date",
    description: "End Date field in trip field model",
  },
  [TripFieldName.NOTE]: {
    id: "trip.field.note",
    defaultMessage: "Notes",
    description: "Note field in trip field model",
  },
  [TripFieldName.PARKING_FEE]: {
    id: "trip.field.parkingFee",
    defaultMessage: "Parking",
    description: "Parking field in trip field model",
  },
  [TripFieldName.RATE]: {
    id: "trip.field.tripRate",
    defaultMessage: "Rate",
    description: "Rate field in trip field model",
  },
  [TripFieldName.RECORD]: {
    id: "trip.field.record",
    defaultMessage: "Record",
    description: "Record field in trip field model",
  },
  [TripFieldName.START]: {
    id: "trip.field.startGeoPoint",
    defaultMessage: "Starting Location",
    description: "Start field in trip field model",
  },
  [TripFieldName.START_TIME]: {
    id: "trip.field.startTime",
    defaultMessage: "Date & Time",
    description: "Date field in trip field model",
  },
  [TripFieldName.TOLL_FEE]: {
    id: "trip.field.tollFee",
    defaultMessage: "Tolls",
    description: "Toll fee field in trip field model",
  },
  [TripFieldName.TOTAL_COST]: {
    id: "trip.field.totalCost",
    defaultMessage: "Mileage Value",
    description: "Total cost field in trip field model",
  },
  [TripFieldName.TRIP_COST]: {
    id: "trip.field.tripCost",
    defaultMessage: "Drive Value",
    description: "Trip cost field in trip field model",
  },
  [TripFieldName.TYPE]: {
    id: "trip.field.type",
    defaultMessage: "Classification",
    description: "Type field in trip field model",
  },
  [TripFieldName.USER]: {
    id: "trip.field.user",
    defaultMessage: "User",
    description: "User field in trip field model",
  },
});

const exportDisplayNames = defineMessages({
  [TripFieldName.START_TIME]: {
    id: "trip.field.startTime.exportName",
    defaultMessage: "Start Date",
    description: "Start Date field export name in trip field model",
  },
});

// All fields in this model are filter-only fields
const tripFieldModel = new FieldModel(
  [
    {
      csvExportProperties: {
        displayName: exportDisplayNames[TripFieldName.START_TIME],
        valueFormatter: csvDateTimeFormatter,
      },
      customGridProperties: {
        cellRenderer: "tripDateCellRenderer",
        initialWidth: 288, // just fine to fit normal start/end dates
      },
      displayName: displayNames[TripFieldName.START_TIME],
      displayOrder: 10,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.WITH_CHECKBOX_SELECTION,
        FieldFeature.ALWAYS_VISIBLE,
      ],
      name: TripFieldName.START_TIME,
      type: FieldType.NUMBER,
      valueFormatter: (entity: unknown, fieldValue: unknown) => {
        const startDate = parseApiDate(fieldValue as Trip["startTime"]);
        if (!isValidDate(startDate)) {
          return "";
        }
        const endTimeString = (entity as Trip).endTime;
        const endDate = endTimeString ? parseApiDate(endTimeString) : undefined;
        if (!endTimeString || !isValidDate(endDate)) {
          return formatDate(startDate, "PPp");
        }

        const formattedStartDate = formatDate(startDate, "PPp");

        if (isSameDay(startDate, endDate)) {
          return i18nService.formatMessage(
            messages.sameDayFormat,
            `${formattedStartDate} - ${formatDate(endDate, "p")}`,
            {
              endTime: formatDate(endDate, "p"),
              startDateTime: formattedStartDate,
            }
          );
        }
        return i18nService.formatMessage(
          messages.differentDaysFormat,
          `${formattedStartDate} - ${formatDate(endDate, "PPp")}`,
          {
            endDateTime: formatDate(endDate, "PPp"),
            startDateTime: formattedStartDate,
          }
        );
      },
    },
    new UserField({
      customGridProperties: {
        cellRenderer: "userChipCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      displayName: displayNames[TripFieldName.USER],
      displayOrder: 20,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SORTABLE,
        FieldFeature.OWNER_FIELD,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.VISIBLE_BY_DEFAULT,
      ],
      name: TripFieldName.USER,
      platformFilterName: "userId",
    }),
    {
      csvExportProperties: {
        valueFormatter: locationValueFormatter,
      },
      customGridProperties: {
        cellRenderer: "tripLocationCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      displayName: displayNames[TripFieldName.START],
      displayOrder: 30,
      features: [FieldFeature.FILTERABLE, FieldFeature.VISIBLE_BY_DEFAULT],
      name: TripFieldName.START,
      type: FieldType.OBJECT,
      valueGetter: (entity: unknown) => {
        const trip = entity as Trip;
        return {
          address: trip.startAddress,
          entity: trip.startEntity,
          geoPoint: trip.startGeopoint,
        };
      },
    },
    {
      csvExportProperties: {
        valueFormatter: locationValueFormatter,
      },
      customGridProperties: {
        cellRenderer: "tripLocationCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      displayName: displayNames[TripFieldName.FINISH],
      displayOrder: 40,
      features: [FieldFeature.FILTERABLE, FieldFeature.VISIBLE_BY_DEFAULT],
      name: TripFieldName.FINISH,
      type: FieldType.OBJECT,
      valueGetter: (entity: unknown) => {
        const trip = entity as Trip;
        return {
          address: trip.endAddress,
          entity: trip.endEntity,
          geoPoint: trip.endGeopoint,
        };
      },
    },
    {
      customGridProperties: {
        cellRenderer: "tripTypeCellRenderer",
      },
      displayName: displayNames[TripFieldName.TYPE],
      displayOrder: 50,
      features: [FieldFeature.FILTERABLE, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.BULK_EDIT],
      name: TripFieldName.TYPE,
      type: FieldType.STRING,
      valueFormatter: (entity: unknown, fieldValue: unknown) => {
        const intl = i18nService.getIntl();
        const value = fieldValue as Trip["type"];
        return intl ? getTripTypeDisplayName(intl, value) : value ?? "";
      },
    },
    {
      customGridProperties: {
        cellRenderer: "editableNoteCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      displayName: displayNames[TripFieldName.NOTE],
      displayOrder: 60,
      features: [FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.BULK_EDIT],
      name: TripFieldName.NOTE,
      type: FieldType.STRING,
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.DISTANCE],
      displayOrder: 70,
      features: [FieldFeature.SORTABLE, FieldFeature.VISIBLE_BY_DEFAULT],
      name: TripFieldName.DISTANCE,
      type: FieldType.NUMBER,
      valueFormatter: meterDistanceFormatter,
    },
    {
      csvExportProperties: {
        valueFormatter: (value: Trip["tripRate"]) => {
          return csvNumberFormatter(value && "rate" in value ? value.rate ?? 0 : 0);
        },
      },
      displayName: displayNames[TripFieldName.RATE],
      displayOrder: 80,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.MONETARY_VALUE,
        FieldFeature.SORTABLE,
      ],
      name: TripFieldName.RATE,
      type: FieldType.OBJECT,
      valueFormatter: (entity: unknown, fieldValue: unknown) => {
        const value = fieldValue as Trip["tripRate"];
        return moneyFormatter(entity, value && "rate" in value ? value.rate ?? 0 : 0);
      },
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.TOTAL_COST],
      displayOrder: 90,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.SORTABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.MONETARY_VALUE,
      ],
      name: TripFieldName.TOTAL_COST,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.PARKING_FEE],
      displayOrder: 100,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.SORTABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.MONETARY_VALUE,
      ],
      name: TripFieldName.PARKING_FEE,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.TOLL_FEE],
      displayOrder: 110,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.SORTABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.MONETARY_VALUE,
      ],
      name: TripFieldName.TOLL_FEE,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.TRIP_COST],
      displayOrder: 120,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.SORTABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.MONETARY_VALUE,
      ],
      name: TripFieldName.TRIP_COST,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
    },
    {
      csvExportProperties: {
        valueFormatter: csvNumberFormatter,
      },
      displayName: displayNames[TripFieldName.BUSINESS_COST],
      displayOrder: 130,
      features: [
        FieldFeature.NUMERIC,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.MONETARY_VALUE,
      ],
      name: TripFieldName.BUSINESS_COST,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
      valueGetter: (entity: unknown) => {
        const trip = entity as Trip;
        return trip.type === TripType.BUSINESS ? trip.tripCost : 0;
      },
    },
    // filter only fields:
    {
      displayName: displayNames[TripFieldName.RECORD],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: TripFieldName.RECORD,
      type: FieldType.ID,
    },
    // export only field
    {
      csvExportProperties: { valueFormatter: csvDateTimeFormatter },
      displayName: displayNames[TripFieldName.FINISH_TIME],
      displayOrder: 15,
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: TripFieldName.FINISH_TIME,
      type: FieldType.NUMBER,
    },
  ].map(createField)
);

export default tripFieldModel;
