import notification from "antd/es/notification";
import { defineMessages } from "react-intl";
import { all, call, delay, put, race, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as uuid from "uuid";

import { EntityType } from "@mapmycustomers/shared";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import TripStatus from "@mapmycustomers/shared/enum/mileageTracking/TripStatus";
import Trip from "@mapmycustomers/shared/types/entity/Trip";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import getFileRemovedNotificationNode from "@app/component/preview/util/getFileRemovedNotificationNode";
import getYourDownloadWillStartShortlyNode from "@app/component/preview/util/getYourDownloadWillStartShortlyNode";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import {
  getMileageTrackingState,
  getTotalFilteredTripRecordsCount,
  getTripPreviewRecordData,
} from "@app/scene/reports/store/mileageTracking/selectors";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import AggregatedListResponse from "@app/types/viewModel/AggregatedListResponse";
import { NOTIFICATION_DURATION_WITH_ACTION } from "@app/util/consts";
import { allSettled, SettleResult } from "@app/util/effects";
import tripFieldModel, { TripFieldName } from "@app/util/fieldModel/tripFieldModel";
import { downloadFileByUrl } from "@app/util/file";
import loggingService from "@app/util/logging";
import { parseApiDate } from "@app/util/parsers";
import { isSimpleCondition } from "@app/util/viewModel/assert";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  applyTripListViewSettings,
  deleteTrip,
  deleteTripFile,
  deleteTrips,
  downloadTripFile,
  downloadTripList,
  fetchThumbnail,
  fetchTripList,
  fetchTripPreviewData,
  initializeTripListView,
  updateTrip,
  uploadTripFiles,
} from "./actions";
import TripRecordData from "./TripRecordData";

const messages = defineMessages({
  deleteSuccess: {
    id: "mileageTracking.delete.success",
    defaultMessage: "{count, plural, one {Drive} other {Drives}} successfully deleted",
    description: "Drive deleted success message",
  },
});

type StatsAggregations = {
  sum_distance: { value: number };
  sum_parkingFee: { value: number };
  sum_tollFee: { value: number };
  sum_tripCost: { value: number };
};

export function* onInitializeListView() {
  const orgId: Organization["id"] = yield select(getOrganizationId);
  const trips: ListResponse<Trip> = yield callApi("fetchTrips", orgId, {
    $limit: 1,
    $order: "startTime",
  });
  const firstTrip = trips.data.length ? trips.data[0] : undefined;
  const firstDateWithData = firstTrip ? parseApiDate(firstTrip.startTime) : undefined;

  yield put(initializeTripListView.success({ firstDateWithData }));
}

export function* onFetchList({ payload }: ReturnType<typeof fetchTripList.request>) {
  try {
    loggingService.debug("mileage tracking store: onFetchList", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyTripListViewSettings(payload.request));
    }

    const listViewState: ViewState = yield select(getMileageTrackingState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(listViewState, "mileageTracking/listView");
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : listViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : listViewState.range.endRow - listViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);

    // We extract the Record field filter and apply it differently in a special way with $or condition
    const filter = { ...listViewState.filter };
    let extraFilters: PlatformFilterModel = {};
    if (TripFieldName.RECORD in filter) {
      const condition = filter[TripFieldName.RECORD];
      if (
        isSimpleCondition(condition) &&
        condition.operator === FilterOperator.EQUALS &&
        condition.value?.id
      ) {
        const id = condition.value.id;
        extraFilters = {
          $or: [{ "startEntity.id": { $eq: id } }, { "endEntity.id": { $eq: id } }],
        };
      }
      delete filter[TripFieldName.RECORD];
    }

    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        status: TripStatus.COMPLETED,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : filter,
          listViewState.columns,
          tripFieldModel,
          true,
          listViewState.viewAs
        ),
        ...extraFilters,
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(listViewState.sort),
    };

    const statsRequestPayload: PlatformListRequest = {
      $aggs: { sum: ["distance", "tollFee", "parkingFee", "tripCost"] },
      $filters: requestPayload.$filters,
      $limit: 0,
    };

    const [response, statsResponse]: [
      ListResponse<Trip>,
      AggregatedListResponse<Trip, StatsAggregations>
    ] = yield all([
      callApi("fetchTrips", orgId, requestPayload),
      callApi("fetchTrips", orgId, statsRequestPayload),
    ]);

    if (payload.dataCallback) {
      yield call(payload.dataCallback, response);
    }

    const { sum_distance, sum_parkingFee, sum_tollFee, sum_tripCost } = statsResponse.aggregations;
    yield put(
      fetchTripList.success({
        stats: {
          parkingFee: sum_parkingFee.value,
          tollFee: sum_tollFee.value,
          totalCount: statsResponse.total,
          totalDistance: sum_distance.value,
          value: sum_tripCost.value,
        },
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    if (payload.failCallback) {
      yield call(payload.failCallback);
    }
    yield put(fetchTripList.failure(error));
    yield put(handleError({ error }));
  }
}

function* onFetchPreviewData({ payload: tripId }: ReturnType<typeof fetchTripPreviewData.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const trip: Trip = yield callApi("fetchTrip", orgId, tripId, { includeAccessStatus: true });
    const filesResponse: ListResponse<RawFile> = yield callApi(
      "fetchEntityFiles",
      orgId,
      EntityType.TRIP,
      trip.id,
      {
        $limit: 1000,
        $order: "-createdAt",
      }
    );
    yield put(fetchTripPreviewData.success({ files: filesResponse.data, trip }));
  } catch (error) {
    yield put(fetchTripPreviewData.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateTrip({ payload }: ReturnType<typeof updateTrip.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedTrip: Trip = yield callApi("updateTrip", orgId, payload.entity);
    if (payload.onSuccess) {
      yield call(payload.onSuccess, updatedTrip);
    }
    yield put(updateTrip.success(updatedTrip));
    yield put(notifyAboutChanges({ entityType: EntityType.TRIP, updated: [updatedTrip] }));
  } catch (error) {
    yield put(updateTrip.failure(error));
    yield put(handleError({ error }));
  }
}

function* onDeleteTrips({ payload }: ReturnType<typeof deleteTrips.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteTrips", orgId, payload.tripIds, payload.reason);
    yield put(deleteTrips.success());
    if (payload.onSuccess) {
      yield call(payload.onSuccess);
    }
    yield put(notifyAboutChanges({ deletedIds: payload.tripIds, entityType: EntityType.TRIP }));
    notification.success({
      message: i18nService.formatMessage(messages.deleteSuccess, "Drive(s) successfully deleted", {
        count: payload.tripIds.length,
      }),
    });
  } catch (error) {
    yield put(deleteTrips.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchThumbnail({ payload }: ReturnType<typeof fetchThumbnail>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { trip }: TripRecordData = yield select(getTripPreviewRecordData);
    if (!trip) {
      return;
    }
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      payload.fileId,
      false,
      true,
      EntityType.TRIP,
      trip.id,
      { responseType: "blob", timeout: 0 } // no timeout
    );
    yield call(payload.callback, fileData);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUploadTripFiles({ payload }: ReturnType<typeof uploadTripFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { trip }: TripRecordData = yield select(getTripPreviewRecordData);
    if (!trip) {
      yield put(uploadTripFiles.failure());
      return;
    }
    const fileGroupId = payload.fileGroupId ?? uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, false, EntityType.TRIP, trip.id, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    if (payload.callback) {
      yield call(payload.callback, fileList);
    }
    yield put(uploadTripFiles.success(fileList));
  } catch (error) {
    if (payload.callback) {
      yield call(
        payload.callback,
        payload.files.map((file) => ({ errored: true, file, uploading: false }))
      );
    }
    yield put(uploadTripFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadTripFile({ payload: file }: ReturnType<typeof downloadTripFile>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { trip }: TripRecordData = yield select(getTripPreviewRecordData);
    if (!trip) {
      return;
    }

    const key = `download_file_${file.id}`;
    const timeout = setTimeout(() => {
      notification.info({
        duration: 0, // don't close automatically, we'll close it ourselves
        key,
        message: getYourDownloadWillStartShortlyNode(i18nService.getIntl(), file.name),
      });
    }, 2000);

    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      EntityType.TRIP,
      trip.id,
      { responseType: "blob", timeout: 0 }
    );
    clearTimeout(timeout);
    notification.close(key);

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDeleteTripFile({ payload }: ReturnType<typeof deleteTripFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { trip }: TripRecordData = yield select(getTripPreviewRecordData);
    if (!trip) {
      yield put(deleteTripFile.failure(payload));
      return;
    }

    const notificationKey = `file_removal_${payload.id}`;
    const cancelled = new Promise<true>((resolve) => {
      notification.info({
        duration: NOTIFICATION_DURATION_WITH_ACTION,
        key: notificationKey,
        message: getFileRemovedNotificationNode(i18nService.getIntl(), payload, () => {
          resolve(true);
          notification.close(notificationKey);
        }),
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let markWindowClosed = (flag: true) => {};
    const windowClosed = new Promise<boolean>((resolve) => {
      markWindowClosed = resolve; // I need to "extract" it from here, so that I can call removeEventListener later
    });
    const documentListener = () => {
      if (document.visibilityState === "hidden") {
        markWindowClosed(true);
        notification.close(notificationKey);
      }
    };
    document.addEventListener("visibilitychange", documentListener);
    const windowListener = () => markWindowClosed(true);
    window.addEventListener("beforeunload", windowListener);

    const result: { cancelled?: Promise<true>; delete?: true; windowClosed?: Promise<true> } =
      yield race({
        cancelled,
        delete: delay(NOTIFICATION_DURATION_WITH_ACTION * 1000 /* since it is in seconds */),
        windowClosed,
      });

    window.removeEventListener("beforeunload", windowListener);
    document.removeEventListener("visibilitychange", documentListener);

    if (result.delete || result.windowClosed) {
      yield callApi("deleteEntityFile", org.id, EntityType.TRIP, trip.id, payload.id);
      yield put(deleteTripFile.success({ file: payload, removed: true }));
    } else {
      yield put(deleteTripFile.success({ file: payload, removed: false }));
    }
  } catch (error) {
    yield put(deleteTripFile.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onDeleteTrip({ payload }: ReturnType<typeof deleteTrip.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { trip }: TripRecordData = yield select(getTripPreviewRecordData);
    if (!trip) {
      yield put(deleteTrip.failure());
      return;
    }

    yield callApi("deleteTrips", org.id, [trip.id], payload.reason);

    yield put(deleteTrip.success());
    notification.success({
      message: i18nService.formatMessage(messages.deleteSuccess, "Drive successfully deleted", {
        count: 1,
      }),
    });
    yield put(notifyAboutChanges({ deletedIds: [trip.id], entityType: EntityType.TRIP }));
  } catch (error) {
    yield put(deleteTrip.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadList({ payload: { columns } }: ReturnType<typeof downloadTripList>) {
  const listViewState: ViewState = yield select(getMileageTrackingState);
  const total: number = yield select(getTotalFilteredTripRecordsCount);
  yield put(
    exportEntities.request({
      entityType: EntityType.TRIP,
      total,
      viewState: { ...listViewState, columns },
    })
  );
}

export function* mileageTrackingSagas() {
  yield takeLatest(initializeTripListView.request, onInitializeListView);
  yield takeLatest(fetchTripList.request, onFetchList);
  yield takeLatest(fetchTripPreviewData.request, onFetchPreviewData);
  yield takeEvery(updateTrip.request, onUpdateTrip);
  yield takeEvery(deleteTrips.request, onDeleteTrips);
  yield takeEvery(fetchThumbnail, onFetchThumbnail);
  yield takeEvery(uploadTripFiles.request, onUploadTripFiles);
  yield takeEvery(downloadTripFile, onDownloadTripFile);
  yield takeEvery(deleteTripFile.request, onDeleteTripFile);
  yield takeEvery(deleteTrip.request, onDeleteTrip);
  yield takeEvery(downloadTripList, onDownloadList);
}
